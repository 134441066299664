@import '../_var.less';

@dim-filters: @dim-sm + 5px;

.FiltersCtn {
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 1rem;
    box-shadow: 0 5px 20px -5px rgba(0,0,0,0.1);
    background-color: @color-white;
    padding: @dim-filters @dim-filters 0 @dim-filters;
    width: 100%;
    box-sizing: border-box;
    margin: @dim-xs 0 @dim-sm 0;
    column-gap: 1rem;
    row-gap: 0.5rem;
    opacity: 1;

    &--hidden {
        display: none;
        opacity: 0;
        transition: opacity .1s ease-out;
    }

    .FilterCtn {
        margin-bottom: @dim-filters;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: .5rem;

        .Filter__label {
            font-weight: 600;
            font-size: @font-xs;
        }
        .InputSearch, .InputDateRange, .Dropdown--search {
            margin-bottom: 0;
        }
    }
}


label.FilterButton {
    .mixin-input-search-style();
    padding: 7px 10px 6px 10px;
    font-size: @font-xs + 0.05em;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    display: inline-block;
    transition: all .05s ease-out;

    &::after {
        .mixin-input-close-style();

        transition: none;
        transform: scale(0);
        opacity: 0;
    }

    ~ input#sort_show_btn[type="checkbox"] {

        &:checked {

        }
    }
}

input#sort_show_btn[type="checkbox"] {
    display: none;

    &:checked {

        ~ label.FilterButton {
            padding-right: 28px;
            transition: all .1s ease-out;

            &::after {
                transform: scale(1);
                opacity: 1;
                transition: all .1s ease-out;
            }
        }
    }
}